export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const SET_USERID = 'SET_USERID';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const GET_BANKLIST = 'GET_BANKLIST';
export const TOGGLE_AUTHENTICATION = 'TOGGLE_AUTHENTICATION';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const REFRESH_TABLE = 'REFRESH_TABLE';
