import React from 'react';
import RevCostPurTable from './RevCostPurTable';

const RevCostPur = () => {
  return (
    <>
      <div
        style={{
          width: '100vw',
          textAlign: 'center',
          margin: 'auto',
        }}
      >
        <RevCostPurTable />
      </div>
    </>
  );
};

export default RevCostPur;
