import React, { useState, useEffect, useContext } from 'react';
import { MDBDataTable } from 'mdbreact';
// import EditFieldModal from '../../components/Modal/EditFieldModal';
import DeleteFieldModal from '../../components/Modal/DeleteFieldModal';
import './Table.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalState';

const Table = ({
  container,
  tableFilter,
  setTableFilter,
  refreshTable,
  backgroundColor,
  startDate,
  onDateChange,
}) => {
  const changeTableFilter = (event) => {
    setTableFilter(event.target.value);
  };

  const { setSignout } = useContext(GlobalContext);

  const [tableRows, setTableRows] = useState([]);

  let flowDirectionEnglish;

  const fetchTableData = async () => {
    // let flowDirectionEnglish;
    switch (container) {
      case 'الإيراد':
        flowDirectionEnglish = 'incoming_money';
        break;
      case 'المصاريف':
        flowDirectionEnglish = 'outgoing_money';
        break;
      case 'المشتريات':
        flowDirectionEnglish = 'purchase_money';
        break;
      default:
        flowDirectionEnglish = '';
        break;
    }

    const date = format(new Date(startDate), 'yyyy-MM-dd');
    try {
      const data = await axios.get(
        `/transaction/${flowDirectionEnglish}/${tableFilter}/${date}`
      );
      if (data) {
        setTableRows(data.data);
      }
    } catch (error) {
      setSignout();
    }
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, tableFilter, startDate]);

  const [tableRowsAndButtons, setTableRowsAndButtons] = useState([]);

  useEffect(() => {
    if (tableRows) {
      setTableRowsAndButtons(
        tableRows?.map((row) => ({
          description: row.description,
          bankName: row.bankName,
          chequeNumber: row.chequeNumber,
          amount: row.amount,
          entrydate: format(new Date(row.entrydate), 'yyyy-MM-dd'),
          id: (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <DeleteFieldModal
                  container={container}
                  editedRow={row}
                  refreshTable={fetchTableData}
                />
              </div>
              {/* <div>
              <EditFieldModal
                container={container}
                editedRow={row}
                id={row.id}
                tableRows={tableRows}
                refreshTable={fetchTableData}
                flowDirectionEnglish={flowDirectionEnglish}
              />
            </div> */}
              {/* إلى حين إشعار آخر   */}
            </div>
          ),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows]);

  const tableData = {
    columns: [
      { label: 'البيان', field: 'description' },
      { label: 'اسم المصرف', field: 'bankName' },
      { label: 'رقم الصك', field: 'chequeNumber' },
      { label: 'القيمة', field: 'amount' },
      { label: 'التاريخ', field: 'entrydate' },
      // { label: "تعديل أو حذف", field: "id" }
      { label: ' حذف', field: 'id' },
    ],
    rows: tableRowsAndButtons,
  };

  return (
    <div>
      <hr />
      <h3 className="mainTableHeader">الجدول اليومي</h3>
      <div className="TableMain">
        <div>
          <label>نقدي</label>
          <input
            type="radio"
            name="table-filter-radio"
            value="1"
            checked={tableFilter === '1'}
            onChange={changeTableFilter}
          />
        </div>
        <div>
          <label>صك</label>
          <input
            type="radio"
            name="table-filter-radio"
            value="2"
            checked={tableFilter === '2'}
            onChange={changeTableFilter}
          />
        </div>
        <div>
          <label> نقدي وصك </label>
          <input
            type="radio"
            name="table-filter-radio"
            value="3"
            checked={tableFilter === '3'}
            onChange={changeTableFilter}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '2rem auto',
        }}
      >
        <DatePicker
          selected={startDate}
          onChange={onDateChange}
          isClearable
          dateFormat="yyyy-MM-dd"
          todayButton="اليوم"
        />
        <label style={{ margin: '0.3rem 0 0 2rem' }}>:اختر اليوم</label>
      </div>
      <MDBDataTable
        style={{
          backgroundColor: backgroundColor,
          borderRadius: '10px',
          textAlign: 'center',
          boxShadow: '5px 5px  5px #ddd',
        }} //dce775 //81d4fa
        striped
        bordered
        small
        data={tableData}
        // order={["id", "desc"]}
      />
    </div>
  );
};

export default Table;
