import axios from 'axios';
import { NumberWithComma } from '../../functions/NumberWithComma';

export const DebtLoanList = async () => {
  const ArrDL = [];
  const clientList = await axios.get('/client');
  const { debtReceived, debtReturned } = (
    await axios.get('/reports/debt')
  ).data;
  const { loanReceived, loanReturned } = (
    await axios.get('/reports/loan')
  ).data;

  for (let i = 0; i < clientList.data.length; i++) {
    const name = clientList.data[i].clientname;
    const clientDebtReceived =
      debtReceived.filter((x) => x.clientname === name)[0]?.sum || 0;
    const clientDebtReturned =
      debtReturned.filter((x) => x.clientname === name)[0]?.sum || 0;
    const debt = clientDebtReceived - clientDebtReturned;
    const clientLoanReceived =
      loanReceived.filter((x) => x.clientname === name)[0]?.sum || 0;
    const clientLoanReturned =
      loanReturned.filter((x) => x.clientname === name)[0]?.sum || 0;
    const loan = clientLoanReceived - clientLoanReturned;
    ArrDL.push({
      number: i + 1,
      clientname: name,
      debtReceived: clientDebtReceived,
      debtReturned: clientDebtReturned,
      debt: debt,
      loanReceived: clientLoanReceived,
      loanReturned: clientLoanReturned,
      loan: loan,
    });
  }
  // Get total of each column
  const DRecTotal = ArrDL.reduce((acc, val) => acc + val.debtReceived, 0);
  const DRetTotal = ArrDL.reduce((acc, val) => acc + val.debtReturned, 0);
  const DTotal = ArrDL.reduce((acc, val) => acc + val.debt, 0);
  const LRecTotal = ArrDL.reduce((acc, val) => acc + val.loanReceived, 0);
  const LRetTotal = ArrDL.reduce((acc, val) => acc + val.loanReturned, 0);
  const LTotal = ArrDL.reduce((acc, val) => acc + val.loan, 0);

  const ArrDLTotals = {
    number: null,
    clientname: 'الإجمالي',
    debtReceived: NumberWithComma(DRecTotal),
    debtReturned: NumberWithComma(DRetTotal),
    debt: NumberWithComma(DTotal),
    loanReceived: NumberWithComma(LRecTotal),
    loanReturned: NumberWithComma(LRetTotal),
    loan: NumberWithComma(LTotal),
  };
  ArrDL.push(ArrDLTotals);
  // console.log(ArrDL);
  return ArrDL;
};
