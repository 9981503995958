import React, { useState, useEffect } from 'react';
import CashReportTable from './CashReportTable';
import ChequeReportTable from './ChequeReportTable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../components/L&D/DatePicker.css';
import axios from 'axios';
import { format } from 'date-fns';
import './TotalsReport.css';
import NavBar from '../NavBar/NavBar';

const TotalsReport = ({ setContainer }) => {
  const [cashStartDate, setCashStartDate] = useState(new Date('2020-01-01'));
  const [cashEndDate, setCashEndDate] = useState(new Date());

  const [chequeStartDate, setChequeStartDate] = useState(
    new Date('2020-01-01')
  );
  const [chequeEndDate, setChequeEndDate] = useState(new Date());

  const onCashStartDateChange = (date) => {
    setCashStartDate(date);
  };
  const onCashEndDateChange = (date) => {
    setCashEndDate(date);
  };

  const onChequeStartDateChange = (date) => {
    setChequeStartDate(date);
  };
  const onChequeEndDateChange = (date) => {
    setChequeEndDate(date);
  };

  const [cash, setCash] = useState({
    openingBalance: 0,
    Revenue: 0,
    Cost: 0,
    Purchase: 0,
    debtReceived: 0,
    debtReturned: 0,
    loanRecevied: 0,
    loanReturned: 0,
    total: 0,
  });

  const getCashFields = async () => {
    const response = await axios.get(`/reports/cash`);
    if (response && response.data.success) {
      setCash(response.data);
    }
  };

  const [cheque, setCheque] = useState({
    openingBalance: 0,
    Revenue: 0,
    Cost: 0,
    Purchase: 0,
    total: 0,
  });

  const getChequeFields = async () => {
    const response = await axios.get(`/reports/cheque`);
    if (response && response.data.success) {
      setCheque(response.data);
    }
  };

  useEffect(() => {
    getCashFields();
    getChequeFields();
  }, []);

  const submitCash = async () => {
    const startDate = format(new Date(cashStartDate), 'yyyy-MM-dd');
    const endDate = format(new Date(cashEndDate), 'yyyy-MM-dd');
    const response = await axios.get(`/reports/cash/${startDate}/${endDate}`);
    if (response && response.data.success) {
      setCash(response.data);
    }
  };

  const clearCash = async () => {
    setCashStartDate(new Date('2020-01-01'));
    setCashEndDate(new Date());
  };

  const submitCheque = async () => {
    const startDate = format(new Date(chequeStartDate), 'yyyy-MM-dd');
    const endDate = format(new Date(chequeEndDate), 'yyyy-MM-dd');
    const response = await axios.get(`/reports/cash/${startDate}/${endDate}`);
    if (response && response.data.success) {
      setCheque(response.data);
    }
  };

  const clearCheque = async () => {
    setChequeStartDate(new Date('2020-01-01'));
    setChequeEndDate(new Date());
  };

  return (
    <>
      <NavBar page="treport" />
      <h1 style={{ textAlign: 'center' }}>تقارير الإجمالي النقدي والبنكي</h1>
      <main className="totalsreport-main">
        <div className="totalsreport-elements">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginBottom: '1rem',
            }}
          >
            <label>من تاريخ :</label>
            <DatePicker
              name="startDate"
              selected={cashStartDate}
              onChange={onCashStartDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginBottom: '1rem',
            }}
          >
            <label>إلى تاريخ :</label>
            <DatePicker
              name="endDate"
              selected={cashEndDate}
              onChange={onCashEndDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '1em auto',
            }}
          >
            <button
              style={{ width: '6rem', borderRadius: '15%' }}
              onClick={submitCash}
            >
              إرسال
            </button>
            <button
              style={{ width: '6rem', borderRadius: '15%' }}
              onClick={clearCash}
            >
              إخلاء
            </button>
          </div>
          <CashReportTable tableHead="إجمالي النقد الحالي" cash={cash} />
        </div>
        <div className="totalsreport-elements">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginBottom: '1rem',
            }}
          >
            <label>من تاريخ :</label>
            <DatePicker
              name="startDate"
              selected={chequeStartDate}
              onChange={onChequeStartDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginBottom: '1rem',
            }}
          >
            <label>إلى تاريخ :</label>
            <DatePicker
              name="endDate"
              selected={chequeEndDate}
              onChange={onChequeEndDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '1em auto',
            }}
          >
            <button
              style={{ width: '6rem', borderRadius: '15%' }}
              onClick={submitCheque}
            >
              إرسال
            </button>
            <button
              style={{ width: '6rem', borderRadius: '15%' }}
              onClick={clearCheque}
            >
              إخلاء
            </button>
          </div>
          <ChequeReportTable tableHead="إجمالي البنكي الحالي" cheque={cheque} />
        </div>
      </main>
    </>
  );
};

export default TotalsReport;
