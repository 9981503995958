import { format } from 'date-fns';

export const reportFilter = (arr, form, startDate, endDate) => {
  let resultArr = arr;
  // console.log(arr);

  //   entrydate: format(new Date(row.entrydate), 'yyyy-MM-dd')

  if (startDate) {
    resultArr = resultArr.filter(
      (row) =>
        format(new Date(row.entrydate), 'yyyy-MM-dd') >=
        format(new Date(startDate), 'yyyy-MM-dd')
    );
  }
  if (endDate) {
    resultArr = resultArr.filter(
      (row) =>
        format(new Date(row.entrydate), 'yyyy-MM-dd') <=
        format(new Date(endDate), 'yyyy-MM-dd')
    );
  }
  if (form.amountFrom) {
    resultArr = resultArr.filter((row) => row.amount >= form.amountFrom);
  }
  if (form.amountTo) {
    resultArr = resultArr.filter((row) => row.amount <= form.amountTo);
  }
  if (form.bank) {
    resultArr = resultArr.filter((row) => row.bankName === form.bank);
  }
  if (form.chequeNumber) {
    resultArr = resultArr.filter(
      (row) => row.chequeNumber === form.chequeNumber
    );
  }
  if (form.paymentType) {
    const typeBoolean = form.paymentType === 'نقدي' ? true : false;
    resultArr = resultArr.filter((row) => row.iscash === typeBoolean);
  }
  if (form.description) {
    resultArr = resultArr.filter((row) => row.description === form.description);
  }
  return resultArr;
};
