import React, { useState, useEffect, useContext } from 'react';
import { MDBDataTable } from 'mdbreact';
import { reportFilter } from '../../functions/reportFilter';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerRCP.css';
import './RevCostPurTable.css';
import { GlobalContext } from '../../context/GlobalState';
import axios from 'axios';
import NavBar from '../NavBar/NavBar';

const tempPaymentType = [
  { id: 1, paymentType: 'نقدي' },
  { id: 2, paymentType: 'صك' },
];

const RevCostPurTable = () => {
  const [table, setTable] = useState([]);
  const tableDataFetch = async () => {
    const revRes = (await axios.get(`/reports/revcostorpur/incoming_money`))
      .data;
    const costRes = (await axios.get(`/reports/revcostorpur/outgoing_money`))
      .data;
    const purRes = (await axios.get(`/reports/revcostorpur/purchase_money`))
      .data;
    if (revRes.success && costRes.success && purRes.success) {
      setTable([revRes.data, costRes.data, purRes.data]);
      setTableRows(revRes.data);
    }
  };

  const [description, setDescription] = useState([]);
  const descriptionListsFetch = async () => {
    const revDesc = (await axios.get(`/management/revenueItems`)).data;
    const costDesc = (await axios.get(`/management/costItems`)).data;
    const purDesc = (await axios.get(`/management/purchaseItems`)).data;
    if (revDesc.success && costDesc.success && purDesc.success) {
      setDescription([revDesc.data, costDesc.data, purDesc.data]);
      setDescriptionList(revDesc.data);
    }
  };
  useEffect(() => {
    tableDataFetch();
    descriptionListsFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tableFilter, setTableFilter] = useState('1');
  const [backgroundColor, setBackgroundColor] = useState('#a7ffeb');

  const { bankList, setBankList } = useContext(GlobalContext);
  useEffect(() => {
    setBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTableFilter = (event) => {
    if (table.length !== 3 || description.length !== 3) return;
    setTableFilter(event.target.value);
    // Update background color
    setTableRows(table[event.target.value - 1]);
    setDescriptionList(description[event.target.value - 1]);
    switch (event.target.value) {
      case '1':
        setBackgroundColor('#a7ffeb');
        break;
      case '2':
        setBackgroundColor('#b3e1ff');
        break;
      case '3':
        setBackgroundColor('#ffcdd2');
        break;
      default:
        break;
    }
  };

  const formInitialState = {
    amountFrom: null,
    amountTo: null,
    bank: '',
    chequeNumber: null,
    paymentType: '',
    description: '',
  };
  const [form, setForm] = useState(formInitialState);

  const formFillout = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const filterTable = () => {
    const filteredTableRows = reportFilter(tableRows, form, startDate, endDate);
    fillTable(filteredTableRows);
  };

  const clearFields = () => {
    fillTable(tableRows);
    setStartDate('');
    setEndDate('');
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const onStartDateChange = (date) => {
    setStartDate(date);
  };
  const onEndDateChange = (date) => {
    setEndDate(date);
  };
  const [tableRows, setTableRows] = useState([]);

  const [descriptionList, setDescriptionList] = useState([]);

  useEffect(() => {
    fillTable(tableRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows]);

  const [tableRowsAndButtons, setTableRowsAndButtons] = useState([]);

  const fillTable = (tableRows) => {
    setTableRowsAndButtons(
      tableRows.map((row) => ({
        description: row.description,
        bankName: row.bankName,
        chequeNumber: row.chequeNumber,
        iscash: row.iscash ? 'نقدي' : 'صك',
        amount: row.amount,
        entrydate: format(new Date(row.entrydate), 'yyyy-MM-dd'),
      }))
    );
  };

  const tableData = {
    columns: [
      { label: 'البيان', field: 'description' },
      { label: 'اسم المصرف', field: 'bankName' },
      { label: 'رقم الصك', field: 'chequeNumber' },
      { label: ' نوع المعاملة', field: 'iscash' },
      { label: 'القيمة', field: 'amount' },
      { label: 'التاريخ', field: 'entrydate' },
    ],
    rows: tableRowsAndButtons,
  };

  return (
    <div>
      <NavBar page="rcpreport" />
      <div className="TableMain">
        <div>
          <label>الإيراد</label>
          <input
            type="radio"
            name="table-filter-radio"
            value="1"
            checked={tableFilter === '1'}
            onChange={changeTableFilter}
          />
        </div>
        <div>
          <label>المصاريف</label>
          <input
            type="radio"
            name="table-filter-radio"
            value="2"
            checked={tableFilter === '2'}
            onChange={changeTableFilter}
          />
        </div>
        <div>
          <label> المشتريات </label>
          <input
            type="radio"
            name="table-filter-radio"
            value="3"
            checked={tableFilter === '3'}
            onChange={changeTableFilter}
          />
        </div>
      </div>
      {/*  Filters setup */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {/* row 1 */}
        <div className="revCostPur-Row">
          <div className="revCostPur-element">
            <label style={{ marginLeft: '0.5rem' }}>تاريخ من:</label>
            <DatePicker
              name="startDate"
              selected={startDate}
              onChange={onStartDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
          </div>
          <div style={{ direction: 'rtl' }}>
            <label style={{ marginLeft: '0.5rem' }}>تاريخ إلى:</label>
            <DatePicker
              name="endDate"
              selected={endDate}
              onChange={onEndDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
          </div>
        </div>

        {/* row 2 */}
        <div className="revCostPur-Row">
          <div className="revCostPur-element">
            <label style={{ marginLeft: '0.5rem' }}>أقـل قيمة : </label>
            <input type="number" name="amountFrom" onChange={formFillout} />
          </div>
          <div style={{ direction: 'rtl' }} className="revCostPur-element">
            <label style={{ marginLeft: '0.5rem' }}>أعلى قيمة: </label>
            <input type="number" name="amountTo" onChange={formFillout} />
          </div>
        </div>
        {/* row 3 */}
        <div className="revCostPur-Row">
          <div className="revCostPur-element">
            <label style={{ marginLeft: '0.5rem' }}>المصـرف:</label>
            <select
              name="bank"
              className="browser-default custom-select custom-dropdown-select"
              value={form.bank}
              onChange={formFillout}
            >
              <option value="">اختر المصرف</option>
              {bankList?.map((item, i) => {
                return (
                  <option key={i} value={item.bankName}>
                    {item.bankName}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ direction: 'rtl' }} className="revCostPur-element">
            <label style={{ marginLeft: '0.5rem' }}>رقم الصك:</label>
            <input type="text" name="chequeNumber" onChange={formFillout} />
          </div>
        </div>
        {/* row 4 */}
        <div className="revCostPur-Row">
          <div className="revCostPur-element">
            <label style={{ marginLeft: '1rem' }}>الدفع:</label>
            <select
              name="paymentType"
              className="browser-default custom-select custom-dropdown-select"
              value={form.paymentType}
              onChange={formFillout}
            >
              <option value="">اختر طريقة الدفع</option>
              {tempPaymentType?.map((item, i) => {
                return (
                  <option key={i} value={item.paymentType}>
                    {item.paymentType}
                  </option>
                );
              })}
            </select>
          </div>
          <div
            style={{
              direction: 'rtl',
            }}
          >
            <label style={{ marginLeft: '1rem' }}>البيان:</label>
            <select
              name="description"
              className="browser-default custom-select custom-dropdown-select"
              value={form.description}
              onChange={formFillout}
            >
              <option value="">اختر البيان</option>
              {descriptionList &&
                descriptionList.map((item, i) => {
                  return (
                    <option key={i} value={item.item}>
                      {item.item}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '2rem',
          justifyContent: 'space-evenly',
        }}
      >
        <button onClick={filterTable} style={{ width: '7rem' }}>
          بحث
        </button>
        <button onClick={clearFields} style={{ width: '7rem' }}>
          إخلاء الحقول
        </button>
      </div>

      <MDBDataTable
        style={{
          borderRadius: '10px',
          textAlign: 'center',
          boxShadow: '5px 5px  5px #ddd',

          backgroundColor: backgroundColor,
        }}
        striped
        bordered
        small
        data={tableData}
      />
    </div>
  );
};

export default RevCostPurTable;
