import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import axios from 'axios';
import './BanksAndClients.css';

const PurchaseManagement = () => {
  const { setAlert, clearAlert } = useContext(GlobalContext);
  const [purchaseItemList, setPurchaseItemList] = useState([]);
  const [purchaseItem, setPurchaseItem] = useState('');
  const [tempPurchaseItem, setTempPurchaseItem] = useState('');
  const [purchaseItemId, setPurchaseItemId] = useState('');

  const getPurchaseItemList = async () => {
    const response = (await axios.get('/management/purchaseItems')).data;
    if (response && response.data) {
      setPurchaseItemList(response.data);
    }
  };

  useEffect(() => {
    getPurchaseItemList();
  }, []);

  const addResource = async () => {
    if (tempPurchaseItem) {
      const response = (
        await axios.post(
          '/management/purchaseItems',

          { item: tempPurchaseItem }
        )
      ).data;
      if (response && response.data) {
        setPurchaseItemList(response.data);
        setPurchaseItem('');
        setTempPurchaseItem('');
      }
    }
  };
  const editResource = async () => {
    if (purchaseItemId) {
      const response = (
        await axios.put(
          `/management/purchaseItems/${purchaseItemId}`,

          { item: tempPurchaseItem }
        )
      ).data;
      if (response && response.data) {
        setPurchaseItemList(response.data);
        setPurchaseItem('');
        setTempPurchaseItem('');
      }
    }
  };

  const deleteResource = async () => {
    if (tempPurchaseItem) {
      clearAlert();
      const deletepurchaseItem = window.confirm(
        `هل أنت متأكد من حذف البيان ${tempPurchaseItem}`
      );
      if (deletepurchaseItem) {
        try {
          const response = (
            await axios.delete(`/management/purchaseItems/${purchaseItemId}`)
          ).data;
          if (response && response.data && response.success) {
            setPurchaseItemList(response.data);
            setPurchaseItem('');
            setTempPurchaseItem('');
          }
        } catch (error) {
          setAlert('purchaseItem has entries and cannot be deleted');
        }
      }
    }
  };

  const handleSelect = (e) => {
    setPurchaseItem(e.target.value);
    e.target.value &&
      setPurchaseItemId(
        purchaseItemList.filter((x) => x.item === e.target.value)[0].id
      );
    setTempPurchaseItem(e.target.value);
  };

  const handleInput = (e) => {
    setTempPurchaseItem(e.target.value);
  };

  return (
    <>
      <div>
        <label>بيان المشتريات</label>
        <br />
        <button
          onClick={addResource}
          className="BCButton"
          style={{ marginLeft: '0.5rem' }}
        >
          &#10004;
        </button>
        <input
          name="resource"
          className="BCInput"
          value={tempPurchaseItem}
          onChange={handleInput}
        />
        <button onClick={editResource} className="BCButton">
          &#9998;
        </button>
        <button onClick={deleteResource} className="BCButton">
          &#10006;
        </button>
        <br />
        <select
          className="BCSelect"
          value={purchaseItem}
          onChange={handleSelect}
        >
          <option value="">البيان</option>
          {purchaseItemList?.map((purchaseItem, i) => {
            return (
              <option key={purchaseItem.id} value={purchaseItem.item}>
                {purchaseItem.item}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default PurchaseManagement;
