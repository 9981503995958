import React, { useState, useEffect } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { DebtLoanList } from './DebtLoanList';
import NavBar from '../NavBar/NavBar';
import './DebtLoanReport.css';

const DebtLoanReport = () => {
  const [debtTable, setDebtTable] = useState([]);
  const [loanTable, setLoanTable] = useState([]);
  const fetchTableData = async () => {
    const ArrDL = await DebtLoanList();
    setDebtTable(
      ArrDL.map((item) => ({
        number: item.number,
        clientname: item.clientname,
        debtReceived: item.debtReceived,
        debtReturned: item.debtReturned,
        debt: item.debt,
      }))
    );
    setLoanTable(
      ArrDL.map((item) => ({
        number: item.number,
        clientname: item.clientname,
        loanReceived: item.loanReceived,
        loanReturned: item.loanReturned,
        loan: item.loan,
      }))
    );
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debt_data_panel = {
    columns: [
      {
        label: 'ر.ت.',
        field: 'number',
        sort: 'asc',
      },
      {
        label: 'الزبون',
        field: 'clientname',
      },
      {
        label: 'الديون المستلمة',
        field: 'debtReceived',
      },
      {
        label: 'الديون المسددة',
        field: 'debtReturned',
      },
      {
        label: 'إجمالي الديون',
        field: 'debt',
      },
    ],
    rows: debtTable,
  };

  const loan_data_panel = {
    columns: [
      {
        label: 'ر.ت.',
        field: 'number',
        sort: 'asc',
      },
      {
        label: 'الزبون',
        field: 'clientname',
      },
      {
        label: 'القروض المستلمة',
        field: 'loanReceived',
      },
      {
        label: 'القروض المسددة',
        field: 'loanReturned',
      },
      {
        label: 'إجمالي القروض',
        field: 'loan',
      },
    ],
    rows: loanTable,
  };

  return (
    <>
      <NavBar page="dlreport" />
      <div className="DebtLoanReport-main">
        <MDBCard narrow style={{ marginBottom: '2rem' }}>
          <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-center align-items-center py-2 mx-4 mb-3">
            <h1 className="white-text mx-3">تقرير الديون</h1>
          </MDBCardHeader>
          <MDBCardBody cascade>
            <MDBTable btn fixed>
              <MDBTableHead columns={debt_data_panel.columns} />
              <MDBTableBody rows={debt_data_panel.rows} />
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
        <MDBCard narrow>
          <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-center align-items-center py-2 mx-4 mb-3">
            <h1 className="white-text mx-3">تقرير القروض</h1>
          </MDBCardHeader>
          <MDBCardBody cascade>
            <MDBTable btn fixed>
              <MDBTableHead columns={loan_data_panel.columns} />
              <MDBTableBody rows={loan_data_panel.rows} />
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      </div>
    </>
  );
};

export default DebtLoanReport;
