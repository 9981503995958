import React from 'react';
import { MDBDataTable } from 'mdbreact';

const LDTable = ({ tableRows }) => {
  const tableData = {
    columns: [
      { label: 'التاريخ', field: 'date' },
      { label: 'القيمة', field: 'amount' },
      { label: 'نوع الإدخال', field: 'entrytype' },
      { label: 'الزبون', field: 'clientname' },
      { label: ' حذف', field: 'id' }
    ],
    rows: tableRows
  };
  return (
    <div>
      <MDBDataTable
        style={{
          backgroundColor: '#a7ffeb',
          borderRadius: '10px',
          textAlign: 'center',
          boxShadow: '5px 5px  5px #ddd'
          //   width: '100%'
        }}
        striped
        bordered
        small
        data={tableData}
        // order={["id", "desc"]}
      />
    </div>
  );
};

export default LDTable;
