import React, { useState, useContext } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBLink,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownToggle,
} from 'mdbreact';
import { GlobalContext } from '../../context/GlobalState';
import { Link } from 'react-router-dom';
import './NavBar.css';
import SignoutModal from './SignoutModal';

const Navbar = ({ page }) => {
  const { profilePicture, user } = useContext(GlobalContext);
  const [collapse, setCollapse] = useState(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevState) => !prevState);
  };
  const toggleCollapse = () => {
    setCollapse((prevState) => !prevState);
  };

  const bgPink = { backgroundColor: 'rgba(0,0,0,.7)', direction: 'rtl' };
  return (
    <div>
      <SignoutModal modal={modal} toggle={toggleModal} />
      <header>
        <MDBNavbar style={bgPink} dark expand="md" scrolling fixed="top">
          <MDBNavbarBrand>
            <div className="d-flex justify-content-between">
              {profilePicture && (
                <div className="navbar-image-div">
                  <img src={profilePicture} alt="profile" />
                </div>
              )}
              {user && <>{user}</>}
              <div className="navbar-signout">
                <button onClick={toggleModal}>تسجيل خروج</button>
              </div>
            </div>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={toggleCollapse} />
          <MDBCollapse isOpen={collapse} navbar>
            <MDBNavbarNav style={{ textAlign: 'right' }} left>
              <MDBNavItem active={page === 'revenue'}>
                <MDBLink to="/revenue">الإيراد</MDBLink>
              </MDBNavItem>
              <MDBNavItem active={page === 'cost'}>
                <MDBLink to="/cost">المصاريف</MDBLink>
              </MDBNavItem>
              <MDBNavItem active={page === 'purchase'}>
                <MDBLink to="/purchases">المشتريات</MDBLink>
              </MDBNavItem>
              <MDBNavItem active={page === 'debtandloan'}>
                <MDBLink to="/loansAndDebts">الديون والقروض</MDBLink>
              </MDBNavItem>
              {/* <MDBNavItem>
                <MDBLink to="/reports">التقارير</MDBLink>
              </MDBNavItem> */}
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="mr-2">التقارير</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem active={page === 'treport'}>
                      <Link to="/reports/TotalsReport">الإجماليات</Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem active={page === 'rcpreport'}>
                      <Link to="/reports/RevCostPur">
                        الإيراد والمصاريف والمشتريات
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem active={page === 'dlreport'}>
                      <Link to="/reports/DebtLoanReport">الديون والقروض</Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem active={page === 'management'}>
                <MDBLink to="/management">الإعدادات</MDBLink>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </header>
      <MDBContainer className="mt-5 pt-5"></MDBContainer>
    </div>
  );
};

export default Navbar;
