import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalState';
import './BanksAndClients.css';

const CostManagement = () => {
  const { setAlert, clearAlert } = useContext(GlobalContext);
  const [costItemList, setCostItemList] = useState([]);
  const [costItem, setCostItem] = useState('');
  const [tempCostItem, setTempCostItem] = useState('');
  const [costItemId, setCostItemId] = useState('');

  const getCostItemList = async () => {
    const response = (await axios.get('/management/costItems')).data;
    if (response && response.data) {
      setCostItemList(response.data);
    }
  };

  useEffect(() => {
    getCostItemList();
  }, []);

  const addResource = async () => {
    if (tempCostItem) {
      const response = (
        await axios.post('/management/costItems', {
          item: tempCostItem,
        })
      ).data;
      if (response && response.data) {
        setCostItemList(response.data);
        setCostItem('');
        setTempCostItem('');
      }
    }
  };
  const editResource = async () => {
    if (costItemId) {
      const response = (
        await axios.put(`/management/costItems/${costItemId}`, {
          item: tempCostItem,
        })
      ).data;
      if (response && response.data) {
        setCostItemList(response.data);
        setCostItem('');
        setTempCostItem('');
      }
    }
  };

  const deleteResource = async () => {
    if (tempCostItem) {
      clearAlert();
      const deletecostItem = window.confirm(
        `هل أنت متأكد من حذف البيان ${tempCostItem}`
      );
      if (deletecostItem) {
        try {
          const response = (
            await axios.delete(`/management/costItems/${costItemId}`)
          ).data;
          if (response && response.success) {
            setCostItemList(response.data);
            setCostItem('');
            setTempCostItem('');
          }
        } catch (error) {
          setAlert('costItem has entries and cannot be deleted');
        }
      }
    }
  };

  const handleSelect = (e) => {
    setCostItem(e.target.value);
    e.target.value &&
      setCostItemId(
        costItemList.filter((x) => x.item === e.target.value)[0].id
      );
    setTempCostItem(e.target.value);
  };

  const handleInput = (e) => {
    setTempCostItem(e.target.value);
  };

  return (
    <>
      <div>
        <label>بيان المصاريف</label>
        <br />
        <button
          onClick={addResource}
          className="BCButton"
          style={{ marginLeft: '0.5rem' }}
        >
          &#10004;
        </button>
        <input
          name="resource"
          className="BCInput"
          value={tempCostItem}
          onChange={handleInput}
        />
        <button onClick={editResource} className="BCButton">
          &#9998;
        </button>
        <button onClick={deleteResource} className="BCButton">
          &#10006;
        </button>
        <br />
        <select className="BCSelect" value={costItem} onChange={handleSelect}>
          <option value="">البيان</option>
          {costItemList?.map((costItem, i) => {
            return (
              <option key={costItem.id} value={costItem.item}>
                {costItem.item}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default CostManagement;
