import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead
} from 'mdbreact';
import { NumberWithComma } from '../../functions/NumberWithComma';

const ChequeReportTable = ({ tableHead, cheque }) => {
  const data_panel = {
    columns: [
      {
        label: 'ر.ت.',
        field: 'number',
        sort: 'asc'
      },
      {
        label: 'العنوان',
        field: 'title'
        // sort: 'asc'
      },
      {
        label: 'القيمة',
        field: 'amount'
        // sort: 'asc'
      }
    ],
    rows: [
      {
        number: '1',
        title: 'الرصيد الافتتاحي',
        amount: NumberWithComma(cheque?.openingBalance)
      },
      {
        number: '2',
        title: 'الإيراد',
        amount: NumberWithComma(cheque?.Revenue)
      },
      {
        number: '3',
        title: 'المصاريف',
        amount: NumberWithComma(cheque?.Cost)
      },
      {
        number: '4',
        title: 'المشتريات',
        amount: NumberWithComma(cheque?.Purchase)
      },
      {
        number: undefined,
        title: 'المجموع',
        amount: NumberWithComma(cheque?.total)
      }
    ]
  };

  return (
    <MDBCard narrow>
      <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-center align-items-center py-2 mx-4 mb-3">
        <h1 className="white-text mx-3">{tableHead}</h1>
      </MDBCardHeader>
      <MDBCardBody cascade>
        <MDBTable btn fixed>
          <MDBTableHead columns={data_panel.columns} />
          <MDBTableBody rows={data_panel.rows} />
        </MDBTable>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ChequeReportTable;
