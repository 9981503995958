import React, { useContext } from 'react';
import BanksAndClients from '../components/Management/BanksAndClients';
import RCP from '../components/Management/RCP';
import { MDBAlert } from 'mdbreact';
import { GlobalContext } from '../context/GlobalState';
import NavBar from '../components/NavBar/NavBar';

const Management = () => {
  const { alert } = useContext(GlobalContext);
  return (
    <>
      <NavBar page="management" />
      {alert && (
        <MDBAlert color="danger" className="text-center">
          {alert}
        </MDBAlert>
      )}
      <BanksAndClients />
      <RCP />
    </>
  );
};

export default Management;
