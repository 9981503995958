import React from 'react';
import './Frontpage.css';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';

const Frontpage = () => {
  return (
    <>
      <NavBar />
      <h1 className="reporttitle">التقارير</h1>
      <div className="frontpage">
        <Link to="/reports/TotalsReport">
          <div className="navtab report-tab-1">الإجماليات</div>
        </Link>
        <Link to="/reports/RevCostPur">
          <div className="navtab report-tab-2">
            الإيراد والمصاريف والمشتريات
          </div>
        </Link>
        <Link to="/reports/DebtLoanReport">
          <div className="navtab report-tab-3">الديون والقروض</div>
        </Link>
      </div>
    </>
  );
};

export default Frontpage;
