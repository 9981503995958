import React, { useState, useEffect, useContext } from 'react';
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import NavBar from '../components/NavBar/NavBar';
import SummationBox from '../components/Summation';
import Table from '../components/Table/Table';
import Form from '../components/Form';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalState';

const container = 'المشتريات';

const Purchases = () => {
  const [tableFilter, setTableFilter] = useState('3');

  const { refreshTable, setRefreshTable } = useContext(GlobalContext);

  const [descriptionList, setDescriptionList] = useState();

  const [startDate, setStartDate] = useState(new Date());

  const onDateChange = (date) => {
    setStartDate(date);
  };

  const fetchDescriptionList = async () => {
    const data = await axios.get(`/management/purchaseItems`);
    data && data.data && setDescriptionList(data.data.data);
  };

  useEffect(() => {
    fetchDescriptionList();
  }, []);

  return (
    <MDBContainer>
      <NavBar page="purchase" />
      <MDBRow className="flex-row-reverse">
        <MDBCol md="12" lg="4">
          <Form container={container} descriptionList={descriptionList} />
        </MDBCol>
        <MDBCol md="12" lg="8">
          <Table
            container={container}
            backgroundColor="#ffcdd2"
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            refreshTable={refreshTable}
            refreshTableFunction={setRefreshTable}
            startDate={startDate}
            onDateChange={onDateChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <SummationBox
          container={container}
          tableFilter={tableFilter}
          startDate={startDate}
        />
      </MDBRow>
    </MDBContainer>
  );
};

export default Purchases;
