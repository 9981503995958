import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalState';
import './BanksAndClients.css';

const RevenueManagement = () => {
  const { setAlert, clearAlert } = useContext(GlobalContext);
  const [revenueItemList, setRevenueItemList] = useState([]);
  const [revenueItem, setRevenueItem] = useState('');
  const [tempRevenueItem, setTempRevenueItem] = useState('');
  const [revenueItemId, setRevenueItemId] = useState('');

  const getRevenueItemList = async () => {
    const response = (await axios.get('/management/revenueItems')).data;
    if (response.data) {
      setRevenueItemList(response.data);
    }
  };

  useEffect(() => {
    getRevenueItemList();
  }, []);

  const addResource = async () => {
    if (tempRevenueItem) {
      const response = (
        await axios.post('/management/revenueItems', {
          item: tempRevenueItem,
        })
      ).data;
      if (response && response.data) {
        setRevenueItemList(response.data);
        setRevenueItem('');
        setTempRevenueItem('');
      } else {
        setAlert(' Server Error, Please try again');
      }
    } else {
      setAlert('Please Insert Value');
    }
  };
  const editResource = async () => {
    if (revenueItemId) {
      const response = (
        await axios.put(`/management/revenueItems/${revenueItemId}`, {
          item: tempRevenueItem,
        })
      ).data;
      if (response && response.data) {
        setRevenueItemList(response.data);
        setRevenueItem('');
        setTempRevenueItem('');
      }
    }
  };

  const deleteResource = async () => {
    clearAlert();
    if (tempRevenueItem) {
      const deleteRevenueItem = window.confirm(
        `هل أنت متأكد من حذف البيان ${tempRevenueItem}`
      );
      if (deleteRevenueItem) {
        try {
          const response = (
            await axios.delete(`/management/revenueItems/${revenueItemId}`)
          ).data;
          if (response && response.success) {
            setRevenueItemList(response.data);
            setRevenueItem('');
            setTempRevenueItem('');
          }
        } catch (error) {
          setAlert('revenueItem has entries and cannot be deleted');
        }
      }
    }
  };

  const handleSelect = (e) => {
    setRevenueItem(e.target.value);
    e.target.value &&
      setRevenueItemId(
        revenueItemList.filter((x) => x.item === e.target.value)[0].id
      );
    setTempRevenueItem(e.target.value);
  };

  const handleInput = (e) => {
    setTempRevenueItem(e.target.value);
  };

  return (
    <>
      <div>
        <label>بيان الإيراد</label>
        <br />
        <button
          onClick={addResource}
          className="BCButton"
          style={{ marginLeft: '0.5rem' }}
        >
          &#10004;
        </button>
        <input
          name="resource"
          className="BCInput"
          value={tempRevenueItem}
          onChange={handleInput}
        />
        <button onClick={editResource} className="BCButton">
          &#9998;
        </button>
        <button onClick={deleteResource} className="BCButton">
          &#10006;
        </button>
        <br />
        <select
          className="BCSelect"
          value={revenueItem}
          onChange={handleSelect}
        >
          <option value="">البيان</option>
          {revenueItemList?.map((revenueItem, i) => {
            return (
              <option key={revenueItem.id} value={revenueItem.item}>
                {revenueItem.item}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default RevenueManagement;
