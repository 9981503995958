import React from 'react';
import './App.css';

import Revenue from './containers/Revenue';
import Cost from './containers/Cost';
import Purchases from './containers/Purchases';
import LoansAndDebts from './containers/L&Ds';
import Reports from './containers/Reports';
import TotalsReport from './components/Reports/TotalsReport';
import RevCostPur from './components/Reports/RevCostPur';
import DebtLoanReport from './components/Reports/DebtLoanReport';
import Management from './containers/Management';
import Signin from './pages/Signin';
import PrivateRoute from './components/PrivateRoute';

import { GlobalProvider } from './context/GlobalState';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <GlobalProvider>
        <Switch>
          <PrivateRoute exact path="/" component={Revenue} />
          <PrivateRoute path="/revenue" component={Revenue} />
          <PrivateRoute path="/cost" component={Cost} />
          <PrivateRoute path="/purchases" component={Purchases} />
          <PrivateRoute path="/loansAndDebts" component={LoansAndDebts} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute path="/management" component={Management} />
          <Route path="/signin" component={Signin} />
          <PrivateRoute
            exact
            path="/reports/DebtLoanReport"
            component={DebtLoanReport}
          />
          <PrivateRoute
            exact
            path="/reports/RevCostPur"
            component={RevCostPur}
          />
          <PrivateRoute
            exact
            path="/reports/TotalsReport"
            component={TotalsReport}
          />
          {/* {isAuthenticated && <ChangeContainer />} */}
        </Switch>
      </GlobalProvider>
    </Router>
  );
};

export default App;
