import React, { useState, useContext } from 'react';
import './Signin.css';
import { MDBBtn, MDBAlert } from 'mdbreact';
import { auth } from '../firebase';
import firebase from 'firebase/app';
import { GlobalContext } from '../context/GlobalState';
import { useHistory } from 'react-router-dom';
import Spinner from '../components/Spinner';

const Signin = () => {
  const [error, setError] = useState('');
  const history = useHistory();
  const { setAuthentication, loading, setLoading } = useContext(GlobalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setError('Sign in failed');
      setLoading(false);
    }, 1000);
    setTimeout(() => setError(''), 5000);
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      setLoading(true);
      setError('');
      await auth.signInWithRedirect(provider);
      console.log('email check passed within handlegooglesingin function');
      history.push('/');
    } catch {
      setError('Failed to sign in');
      setAuthentication(false);
    }
    setLoading(false);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="log-form">
      <h2>Login to your account</h2>
      {error && <MDBAlert color="danger">{error}</MDBAlert>}
      <form>
        <input type="text" title="username" placeholder="username" />
        <input type="password" title="username" placeholder="password" />
        <MDBBtn color="info" onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <div className="spinner-border text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            'Login'
          )}
        </MDBBtn>
        <MDBBtn color="success" onClick={handleGoogleSignIn} disabled={loading}>
          {loading ? (
            <div className="spinner-border text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            'Google'
          )}
        </MDBBtn>
      </form>
    </div>
  );
};

export default Signin;
