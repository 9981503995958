import React from 'react';
import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import RevenueManagement from './RevenueManagement';
import CostManagement from './CostManagement';
import PurchaseManagement from './PurchaseManagement';
import './BanksAndClients.css';

const RCP = () => {
  return (
    <MDBContainer className="mt-5 text-center">
      <MDBRow>
        <MDBCol>
          <MDBJumbotron className="BCJumbotron">
            <RevenueManagement />
            <CostManagement />
            <PurchaseManagement />
          </MDBJumbotron>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default RCP;
