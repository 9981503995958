import React, { useState, useEffect, useContext } from 'react';
import LDTable from '../components/Table/LDTable';
import CardFront from '../components/L&D/CardFront';
import CardBack from '../components/L&D/CardBack';
import '../components/L&D/L&D.css';
import '../components/L&D/L&Dmedia.css';
import '../components/L&D/Switch.css';
import { format } from 'date-fns';
import { MDBTable, MDBTableBody, MDBTableHead, MDBAlert } from 'mdbreact';
import { GlobalContext } from '../context/GlobalState';
import axios from 'axios';
import NavBar from '../components/NavBar/NavBar';

const LoansAndDebts = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { alert } = useContext(GlobalContext);
  const [state, setState] = useState('ديون');

  const flipCard = (e) => {
    setState(e.target.value);
    setIsFlipped(!isFlipped);
  };

  const [tableRows, setTableRows] = useState([]);

  const confirmDeleteDialog = async (table, id) => {
    const data = await axios.get(`/client/Entry/${table}/${id}`);
    if (data.data) {
      const deleteRow = window.confirm(
        `
      هل أنت متأكد من أنك تريد حذف الإدخال بالبيانات التالية:
      الزبون : ${data.data[0].clientname}
      نوع الإدخال : ${data.data[0].entrytype}
      القيمة : ${data.data[0].amount}
      التاريخ : ${format(new Date(data.data[0].date), 'dd-MM-yyyy')}
      
      `
      );
      if (deleteRow) {
        const del = await axios.delete(`/client/Entry/${table}/${id}`);
        if (del.data.success) {
          const tableName =
            state === 'ديون' ? 'client_debt_entry' : 'client_loan_entry';
          getTableData(
            `/client/Entries/${tableName}/${selectedClient[0].clientname}`
          );
        }
      }
    }
  };

  const getTableData = async (link) => {
    const data = await axios.get(link);
    setTableRows(
      data.data.map((row) => ({
        id: (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              name={row.id}
              className="ModalButton"
              style={{ backgroundColor: '#ff4444' }}
              onClick={(e) =>
                confirmDeleteDialog(
                  state === 'ديون' ? 'client_debt_entry' : 'client_loan_entry',
                  e.target.name
                )
              }
            >
              &#10006;
            </button>
          </div>
        ),
        clientname: row.clientname,
        entrytype: row.entrytype,
        amount: row.amount,
        date: format(new Date(row.date), 'yyyy-MM-dd'),
      }))
    );
  };

  const paybackSum =
    tableRows
      .filter((row) => row.entrytype === 'سداد')
      .reduce((acc, cur) => {
        return acc + cur.amount;
      }, 0) || 0;

  const receiveSum =
    tableRows
      .filter((row) => row.entrytype === 'استلام')
      .reduce((acc, cur) => {
        return acc + cur.amount;
      }, 0) || 0;

  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    if (selectedClient.length) {
      if (state === 'ديون') {
        if (selectedClient[0].debtentries) {
          getTableData(
            `/Client/Entries/client_debt_entry/${selectedClient[0].clientname}`
          );
        } else {
          setTableRows([]);
        }
      } else if (state === 'قروض') {
        if (selectedClient[0].loanentries) {
          getTableData(
            `/Client/Entries/client_loan_entry/${selectedClient[0].clientname}`
          );
        } else {
          setTableRows([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, state]);

  return (
    <>
      <NavBar page="debtandloan" />

      <div
        className="pricing-switcher"
        style={{ backgroundColor: '#9e9e9e', marginBottom: '3rem' }}
      >
        <p className="fieldset">
          <input
            type="radio"
            name="duration-1"
            value="قروض"
            id="yearly-1"
            checked={state === 'قروض'}
            onChange={flipCard}
          />
          <label htmlFor="yearly-1">قروض</label>
          <input
            type="radio"
            name="duration-1"
            value="ديون"
            id="monthly-1"
            checked={state === 'ديون'}
            onChange={flipCard}
          />
          <label htmlFor="monthly-1">ديون</label>
          <span className="switch"></span>
        </p>
      </div>
      {alert && (
        <MDBAlert color="danger" className="text-center">
          {alert}
        </MDBAlert>
      )}
      <div className="scene">
        <div className={`card ${isFlipped ? 'is-flipped' : ''}`}>
          <div className={`card__face card__face--front`}>
            <CardFront
              setSelectedClient={setSelectedClient}
              getTableData={getTableData}
            />
          </div>
          <div className={`card__face card__face--back`}>
            <CardBack
              setSelectedClient={setSelectedClient}
              getTableData={getTableData}
            />
          </div>
        </div>
      </div>
      <div
        className={
          state === 'ديون'
            ? selectedClient[0]?.debtentries
              ? 'tableOn'
              : 'tableOff'
            : state === 'قروض'
            ? selectedClient[0]?.loanentries
              ? 'tableOn'
              : 'tableOff'
            : null
        }
      >
        <LDTable tableRows={tableRows} />

        <div className="sumTable">
          <MDBTable>
            <MDBTableHead style={{ backgroundColor: '#9e9e9e' }} textWhite>
              <tr>
                <th>إجمالي ال{state}</th>
                <th>سداد</th>
                <th>استلام</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td
                  style={
                    receiveSum - paybackSum > 0
                      ? { color: 'red' }
                      : { color: 'green' }
                  }
                >
                  {receiveSum - paybackSum}
                </td>
                <td>{paybackSum}</td>
                <td>{receiveSum}</td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>
    </>
  );
};

export default LoansAndDebts;
