import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { auth } from '../firebase';

const initialState = {
  user: '',
  profilePicture: '',
  token: '',
  alert: '',
  paymentMethod: 'نقدي',
  bankList: [],
  isAuthenticated: false,
  loading: true,
  refreshTable: false,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const history = useHistory();

  const setUser = (userName) => {
    dispatch({
      type: 'SET_USERNAME',
      payload: userName,
    });
  };

  const setProfilePicture = (pictureURL) => {
    dispatch({
      type: 'SET_PROFILE_PICTURE',
      payload: pictureURL,
    });
  };

  const setToken = (token) => {
    axios.defaults.headers.common['x-auth-token'] = token;
    dispatch({
      type: 'SET_TOKEN',
      payload: token,
    });
  };

  const setAlert = (alertMessage) => {
    dispatch({
      type: 'SET_ALERT',
      payload: alertMessage,
    });
    setTimeout(() => {
      clearAlert();
    }, 5000);
  };

  const clearAlert = () => {
    dispatch({
      type: 'CLEAR_ALERT',
    });
  };

  const deleteToken = () => {
    delete axios.defaults.headers.common['x-auth-token'];
    dispatch({
      type: 'DELETE_TOKEN',
    });
  };

  const changePaymentMethod = (method) => {
    dispatch({
      type: 'CHANGE_PAYMENT_METHOD',
      payload: method,
    });
  };

  const setBankList = async () => {
    // const data = await getRequest(`/getBankList`);
    const data = (await axios.get(`/management/banks`)).data;
    if (data) {
      dispatch({
        type: 'GET_BANKLIST',
        payload: data.data,
      });
    }
  };

  const setAuthentication = (AuthState) => {
    dispatch({
      type: 'TOGGLE_AUTHENTICATION',
      payload: AuthState,
    });
  };

  const setLoading = (loadingState) => {
    dispatch({
      type: 'TOGGLE_LOADING',
      payload: loadingState,
    });
  };

  const setSignout = () => {
    setUser('');
    setAuthentication(false);
    deleteToken();
    history.push('/signin');
    return auth.signOut();
  };

  const setRefreshTable = () => {
    dispatch({
      type: 'REFRESH_TABLE',
      payload: !state.refreshTable,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        paymentMethod: state.paymentMethod,
        changePaymentMethod,
        user: state.user,
        setUser,
        profilePicture: state.profilePicture,
        setProfilePicture,
        token: state.token,
        setToken,
        deleteToken,
        alert: state.alert,
        setAlert,
        clearAlert,
        isAuthenticated: state.isAuthenticated,
        setAuthentication,
        bankList: state.bankList,
        setBankList,
        loading: state.loading,
        setLoading,
        setSignout,
        refreshTable: state.refreshTable,
        setRefreshTable,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
