import {
  CHANGE_PAYMENT_METHOD,
  SET_USERNAME,
  SET_PROFILE_PICTURE,
  SET_USERID,
  SET_TOKEN,
  SET_ALERT,
  CLEAR_ALERT,
  DELETE_TOKEN,
  GET_BANKLIST,
  TOGGLE_AUTHENTICATION,
  TOGGLE_LOADING,
  REFRESH_TABLE,
} from './Constants';

const AppReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case SET_USERNAME:
      return { ...state, user: action.payload };
    case SET_PROFILE_PICTURE:
      return { ...state, profilePicture: action.payload };
    case SET_USERID:
      return { ...state, uid: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case DELETE_TOKEN:
      return { ...state, token: '' };
    case SET_ALERT:
      return { ...state, alert: action.payload };
    case CLEAR_ALERT:
      return { ...state, alert: '' };
    case GET_BANKLIST:
      return { ...state, bankList: action.payload };
    case TOGGLE_AUTHENTICATION:
      return { ...state, isAuthenticated: action.payload };
    case TOGGLE_LOADING:
      return { ...state, loading: action.payload };
    case REFRESH_TABLE:
      return { ...state, refreshTable: action.payload };
    default:
      return state;
  }
};

export default AppReducer;
