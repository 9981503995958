import React, { useState, useEffect, useContext } from 'react';
import { MDBJumbotron, MDBContainer } from 'mdbreact';
import axios from 'axios';
import { format } from 'date-fns';
import { NumberWithComma } from '../functions/NumberWithComma';
import { GlobalContext } from '../context/GlobalState';

const Summation = ({ container, tableFilter, startDate }) => {
  const [total, setTotal] = useState();
  const { refreshTable } = useContext(GlobalContext);

  const updateSum = async () => {
    let flowDirectionEnglish;
    if (container === 'الإيراد') {
      flowDirectionEnglish = 'incoming_money';
    } else if (container === 'المصاريف') {
      flowDirectionEnglish = 'outgoing_money';
    } else if (container === 'المشتريات') {
      flowDirectionEnglish = 'purchase_money';
    }
    const data = (
      await axios.get(
        `/summationBox/${flowDirectionEnglish}/${tableFilter}/${format(
          new Date(startDate),
          'yyyy-MM-dd'
        )}`
      )
    ).data;
    data ? setTotal(data) : setTotal(0);
  };

  useEffect(() => {
    updateSum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, tableFilter, startDate, refreshTable]);

  return (
    <MDBJumbotron
      style={{ background: '#e0e0e0', width: '100%', margin: '4rem auto' }}
    >
      <MDBContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>
          <h1>
            <strong>الإجمالي</strong>
          </h1>
          <br />
        </div>
        <div>
          <h5>{total && NumberWithComma(total)}</h5>
        </div>
      </MDBContainer>
    </MDBJumbotron>
  );
};

export default Summation;
