import React, { useState, useContext } from 'react';
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBAlert,
} from 'mdbreact';
import { GlobalContext } from '../../context/GlobalState';
import axios from 'axios';
import { format } from 'date-fns';
import './Modal.css';

const DeleteFieldModal = ({ editedRow, container }) => {
  const { alert, setAlert, clearAlert, setRefreshTable } = useContext(
    GlobalContext
  );
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };

  const [deleteReason, setDeleteReason] = useState('');

  const {
    description,
    bankName,
    chequeNumber,
    amount,
    entrydate,
    id,
  } = editedRow;

  const deleteRow = async () => {
    clearAlert();
    if (!deleteReason) {
      setAlert('Please insert delete reason');
    } else {
      // console.log('delete function running');
      const flowDirection = container;
      const formInfo = { id, deleteReason, flowDirection };
      const data = await axios.delete('/transaction', { data: formInfo });
      if (data.data) {
        toggleModal();
        setRefreshTable();
      }
    }
  };

  return (
    <MDBContainer>
      <button
        name={id}
        className="ModalButton"
        style={{ backgroundColor: '#ff4444' }}
        onClick={toggleModal}
      >
        <MDBIcon icon="trash-alt" />
      </button>

      <MDBModal isOpen={open} toggle={toggleModal} size="sm">
        <MDBModalHeader toggle={toggleModal}>DELETE ROW</MDBModalHeader>
        <MDBModalBody>
          {alert && (
            <MDBAlert color="danger" className="text-center">
              {alert}
            </MDBAlert>
          )}
          <div className="ModalDivs">
            <label>:تاريخ الإدخال</label>
            <p>{format(new Date(entrydate), 'yyyy-MM-dd')}</p>
          </div>
          <div className="ModalDivs">
            <label>:القيمة</label>
            <p>{amount}</p>
          </div>
          <div className="ModalDivs">
            <label>:اسم المصرف</label>
            <p>{bankName}</p>
          </div>
          <div className="ModalDivs">
            <label>:رقم الصك</label>
            <p>{chequeNumber}</p>
          </div>
          <div className="ModalDivs">
            <label>:البيان</label>
            <p>{description}</p>
          </div>
          <div className="ModalDivs">
            <label>:سبب الحذف</label>
            <input
              type="text"
              onChange={(e) => setDeleteReason(e.target.value)}
            />
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" size="sm" onClick={toggleModal}>
            Close
          </MDBBtn>
          <MDBBtn color="primary" size="sm" onClick={deleteRow}>
            Save changes
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default DeleteFieldModal;
