import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { MDBBtn } from 'mdbreact';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalState';

const option1 = 'استلام';
const option2 = 'سداد';

const receiveOrPay = [
  { id: 1, option: option1 },
  { id: 2, option: option2 },
];

const transactionType = 'ديون';

const CardFront = ({ setSelectedClient, getTableData }) => {
  const { setSignout, setAlert, clearAlert } = useContext(GlobalContext);
  const formInitialState = { clientName: '', amount: 0, entrytype: '' };
  const [form, setForm] = useState(formInitialState);

  const [clientList, setClientList] = useState([]);

  const getClientNameList = async () => {
    try {
      const data = await axios.get('/client');
      setClientList(data.data);
    } catch (error) {
      setSignout();
    }
  };

  useEffect(() => {
    setSelectedClient(
      clientList.filter((client) => client.clientname === form.clientName)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.clientName]);

  useEffect(() => {
    getClientNameList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formFillout = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [date, setDate] = useState(new Date());

  const onDateChange = (date) => {
    setDate(date);
  };

  const submitForm = async () => {
    if (form.clientName && form.amount && form.entrytype) {
      clearAlert();
      try {
        const data = await axios.post('/Client/Entry', {
          ...form,
          date,
          transactionType,
        });
        if (data.data.success) {
          getTableData(`/Client/Entries/client_debt_entry/${form.clientName}`);
          setForm({ ...form, amount: 0, entrytype: '' });
        } else if (!data.data.success) {
          setSignout();
        }
      } catch (error) {
        // if(!data.data.success)
        // setSignout();
      }
    } else {
      setAlert('Please fill ALL required fields');
    }
  };

  return (
    <>
      {/* <div
        className="pricing-switcher"
        style={{ backgroundColor: '#9e9e9e', marginBottom: '3rem' }}
      >
        <p className="fieldset">
          <input
            type="radio"
            name="duration-1"
            value="قروض"
            id="yearly-1"
            checked={false}
            // checked={state === 'قروض'}
            // onChange={flipCard}
          />
          <label htmlFor="yearly-1">قروض</label>
          <input
            type="radio"
            name="duration-1"
            value="ديون"
            id="monthly-1"
            checked={true}
            // checked={state === 'ديون'}
            // onChange={flipCard}
          />
          <label htmlFor="monthly-1">ديون</label>
          <span className="switch"></span>
        </p>
      </div> */}

      <div
        style={{
          lineHeight: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '2rem auto',
        }}
      >
        <span style={{ marginBottom: '12px' }}>الزبون</span>

        <select
          name="clientName"
          className="browser-default custom-select"
          style={{
            direction: 'rtl',
            width: '58%',
            height: '3rem',
            fontSize: '1.5rem',
            border: 'none',
          }}
          value={form.clientName}
          onChange={formFillout}
        >
          <option value="">اسم الزبون</option>
          {clientList?.map((client, i) => {
            return (
              <option key={i} value={client.clientname}>
                {client.clientname}
              </option>
            );
          })}
        </select>
      </div>
      <div
        style={{
          lineHeight: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '2rem auto',
        }}
      >
        <span style={{ marginBottom: '12px' }}>القيمة</span>
        <input
          name="amount"
          type="number"
          className="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroupMaterial-sizing-default"
          style={{
            direction: 'rtl',
            width: '58%',
            height: '3rem',
            fontSize: '1.5rem',
            border: 'none',
          }}
          value={form?.amount}
          onChange={formFillout}
        />
      </div>
      <div
        style={{
          lineHeight: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '3rem',
        }}
      >
        <span style={{ marginBottom: '12px' }}>التاريخ</span>
        <DatePicker
          name="date"
          selected={date}
          onChange={onDateChange}
          isClearable
          dateFormat="yyyy-MM-dd"
          todayButton="اليوم"
        />
      </div>
      <div
        style={{
          lineHeight: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <span style={{ marginBottom: '12px' }}>النوع</span>
        <select
          name="entrytype"
          className="browser-default custom-select"
          value={form.entrytype}
          onChange={formFillout}
          style={{
            direction: 'rtl',
            width: '58%',
            height: '3rem',
            fontSize: '1.5rem',
            border: 'none',
            borderBottom: '2rem',
          }}
        >
          <option value="">اختر النوع</option>
          {receiveOrPay?.map((option, i) => {
            return (
              <option key={i} value={option.option}>
                {option.option}
              </option>
            );
          })}
        </select>

        <MDBBtn
          style={{
            borderRadius: '30%',
            fontSize: '1.8rem',
            margin: '2rem auto',
            border: '2px solid white',
          }}
          color="blue-grey"
          onClick={submitForm}
        >
          حفظ
        </MDBBtn>
        {/* <button
          style={{ marginTop: '2rem', padding: '1rem' }}
          onClick={submitForm}
        >
          submit
        </button> */}
      </div>
    </>
  );
};

export default CardFront;
