import React, { useState, useEffect } from 'react';
import './BanksAndClients.css';
import axios from 'axios';
// import {GlobalContext} from '../../context/GlobalState'

const BanksManagement = () => {
  // const {banklist ,setBankList, updateBankListItem, deleteBankListItem} = useContext(GlobalContext)
  const [bankList, setBankList] = useState([]);
  const [bank, setBank] = useState('');
  const [tempBank, setTempBank] = useState('');
  const [id, setId] = useState('');

  const getBankList = async () => {
    const response = (await axios.get('/management/banks')).data;
    if (response && response.data) {
      setBankList(response.data);
    }
  };

  useEffect(() => {
    getBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addResource = async () => {
    if (tempBank) {
      const response = (
        await axios.post('/management/banks', {
          bankName: tempBank,
        })
      ).data;
      if (response && response.data) {
        setBankList(response.data);
        setBank('');
        setTempBank('');
      }
    }
  };
  const editResource = async () => {
    if (id) {
      const response = (
        await axios.put(`/management/banks/${id}`, { bankName: tempBank })
      ).data;
      if (response && response.success) {
        setBankList(response.data);
        setBank('');
        setTempBank('');
      }
    }
  };

  const deleteResource = async () => {
    if (tempBank) {
      const deleteBank = window.confirm(
        `هل أنت متأكد من حذف المصرف ${tempBank}`
      );
      if (deleteBank) {
        const response = (await axios.delete(`/management/banks/${id}`)).data;
        if (response && response.success) {
          setBankList(response.data);
          setBank('');
          setTempBank('');
        } else if (!response.success) {
          console.log('bank cannot be deleted');
        }
      }
    }
  };

  const handleSelect = (e) => {
    setBank(e.target.value);
    e.target.value &&
      setId(
        bankList.filter((x) => x.bankName === e.target.value)[0].id // changed after db changed
      );
    setTempBank(e.target.value);
  };

  const handleInput = (e) => {
    setTempBank(e.target.value);
  };

  return (
    <>
      <div>
        <label>خيارات المصرف</label>
        <br />
        <button
          onClick={addResource}
          className="BCButton"
          style={{ marginLeft: '0.5rem' }}
        >
          &#10004;
        </button>
        <input
          name="resource"
          className="BCInput"
          value={tempBank}
          onChange={handleInput}
        />
        <button onClick={editResource} className="BCButton">
          &#9998;
        </button>
        <button onClick={deleteResource} className="BCButton">
          &#10006;
        </button>
        <br />
        <select className="BCSelect" value={bank} onChange={handleSelect}>
          <option value="">اسم المصرف</option>
          {bankList?.map((bank, i) => {
            return (
              <option key={bank.id} value={bank.bankName}>
                {/*changed after db changed*/}
                {bank.bankName}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default BanksManagement;
