import React, { useContext } from 'react';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';
import { GlobalContext } from '../../context/GlobalState';

const SignoutModal = ({ modal, toggle }) => {
  const { setSignout } = useContext(GlobalContext);
  return (
    <MDBContainer>
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}></MDBModalHeader>
        <MDBModalBody style={{ textAlign: 'center' }}>
          هل أنت متأكد من تسجيل الخروج ؟
        </MDBModalBody>
        <MDBModalFooter style={{ justifyContent: 'space-evenly' }}>
          <MDBBtn color="success" onClick={toggle}>
            عودة
          </MDBBtn>
          <MDBBtn color="danger" onClick={setSignout}>
            نعم
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default SignoutModal;
