import React from 'react';
import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import ClientsManagement from './ClientsManagement';
import BanksManagement from './BanksManagement';

const BanksAndClients = () => {
  return (
    <MDBContainer className="mt-5 text-center">
      <MDBRow>
        <MDBCol>
          <MDBJumbotron className="BCJumbotron">
            <ClientsManagement />
            <BanksManagement />
          </MDBJumbotron>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default BanksAndClients;
