import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalState';
import { auth } from '../firebase';
import zeidProfilePicture from '../components/NavBar/zeid-profile-picture.jpg';
import omarProfilePicture from '../components/NavBar/omar-profile-picture.jpg';
import abdulrahmanProfilePicture from '../components/NavBar/abdulrahman-profile-picture.jpg';

const Spinner = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    setLoading,
    setAuthentication,
    setUser,
    setToken,
    setProfilePicture,
  } = useContext(GlobalContext);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setAuthentication(false);
        setLoading(false);
        return auth.signOut();
      }
      // if (location.pathname !== '/') {
      //   history.push('/');
      // }

      if (user.email === 'zmagboub@gmail.com') {
        setUser('زيد');
        setProfilePicture(zeidProfilePicture);
        // console.log(user.uid);
        // setUserID(user.uid);
        // const test = await axios.get(`/tester/${user.uid}`);
        setToken(await auth.currentUser.getIdToken());
        // setToken(user.refreshToken);
        setAuthentication(true);
        if (location.pathname === '/signin') {
          history.push('/');
        }
      } else if (user.email === 'omarkhash@gmail.com') {
        setUser('عمر');
        setProfilePicture(omarProfilePicture);
        // setToken(user.refreshToken);
        setToken(await auth.currentUser.getIdToken());
        setAuthentication(true);
        if (location.pathname === '/signin') {
          history.push('/');
        }
      } else if (user.email === 'abdul922008@gmail.com') {
        setUser('عبد الرحمن');
        setProfilePicture(abdulrahmanProfilePicture);
        // setToken(user.refreshToken);
        setToken(await auth.currentUser.getIdToken());
        setAuthentication(true);
        if (location.pathname === '/signin') {
          history.push('/');
        }
      } else {
        setAuthentication(false);
        return auth.signOut();
      }
      setLoading(false);
    });
    return unsubscribe;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={containerStyler}>
      <div className="spinner-border" role="status" style={elementStyler}>
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-border" role="status" style={elementStyler}>
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-border" role="status" style={elementStyler}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

const containerStyler = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const elementStyler = {
  fontSize: '2rem',
  color: '#2096ff',
  margin: '1rem',
};

export default Spinner;
