import React, { useState, useEffect, useContext } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GlobalContext } from '../context/GlobalState';

const Form = ({ container, descriptionList }) => {
  const initialFormState = {
    amount: 0,
    description: '',
    cheque: '',
    bank: '',
    startDate: new Date(),
  };

  const {
    paymentMethod,
    changePaymentMethod,
    bankList,
    setBankList,
    setAlert,
    clearAlert,
    setRefreshTable,
  } = useContext(GlobalContext);

  const togglePaymentMethod = () => {
    setForm(initialFormState);
    if (paymentMethod === 'نقدي') {
      changePaymentMethod('صك');
    } else if (paymentMethod === 'صك') {
      changePaymentMethod('نقدي');
    }
  };

  useEffect(() => {
    setBankList();
    return () => {
      changePaymentMethod('نقدي'); // on component dismount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, setForm] = useState(initialFormState);

  const formFillout = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const clearForm = () => {
    setForm(initialFormState);
  };

  const submitForm = async () => {
    clearAlert();
    if (
      container === 'الإيراد' &&
      paymentMethod === 'نقدي' &&
      form.description === ''
    ) {
      form.description = '-';
    }
    const { amount, description, cheque, bank } = form;
    if (
      amount === null ||
      amount === '' ||
      description === '' ||
      startDate === null
    ) {
      setAlert('Please fill ALL fields');
    } else if (paymentMethod === 'صك' && (cheque === '' || bank === '')) {
      setAlert('Please fill ALL fields');
    } else {
      const data = await axios.post('/transaction', {
        ...form,
        entrydate: startDate,
        paymentMethod: paymentMethod,
        flowDirection: container,
      });
      if (data) {
        clearForm();
        setRefreshTable();
      }
    }
  };

  const [startDate, setStartDate] = useState(new Date());

  const onDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <>
      <div
        className="card"
        style={{ textAlign: 'center', borderRadius: '1.5rem' }}
      >
        <div className="card-body">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitches"
              onChange={togglePaymentMethod}
              readOnly
            />
            <label className="custom-control-label" htmlFor="customSwitches">
              {/* نقدي أو صك */}
            </label>
          </div>

          <h3 className="card-title">{paymentMethod}</h3>

          <div className="md-form input-group d-flex justify-content-end">
            <DatePicker
              selected={startDate}
              onChange={onDateChange}
              isClearable
              dateFormat="yyyy-MM-dd"
              todayButton="اليوم"
            />
            <div className="input-group-prepend">
              <span
                className="input-group-text md-addon"
                id="inputGroupMaterial-sizing-default"
              >
                التاريخ
              </span>
            </div>
          </div>

          <div className="md-form input-group">
            <input
              name="amount"
              type="number"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroupMaterial-sizing-default"
              style={{ direction: 'rtl' }}
              value={form?.amount}
              onChange={formFillout}
            />
            <div className="input-group-prepend">
              <span
                className="input-group-text md-addon"
                id="inputGroupMaterial-sizing-default"
              >
                القيمة
              </span>
            </div>
          </div>

          {/* <div className={`md-form input-group ${chequeFields[0]}`}> */}
          <div
            className={`md-form input-group ${
              container === 'الإيراد' && paymentMethod === 'نقدي'
                ? ''
                : 'd-none'
            }`}
          >
            <input
              name="description"
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroupMaterial-sizing-default"
              style={{ direction: 'rtl' }}
              value={form.description}
              onChange={formFillout}
            />
            <div className="input-group-prepend">
              <span
                className="input-group-text md-addon"
                id="inputGroupMaterial-sizing-default"
              >
                البيان
              </span>
            </div>
          </div>

          <div
            className={`md-form input-group ${
              container === 'الإيراد' && paymentMethod === 'نقدي'
                ? 'd-none'
                : ''
            }`}
          >
            <select
              name="description"
              className="browser-default custom-select"
              style={{ direction: 'rtl' }}
              value={form.description}
              onChange={formFillout}
            >
              <option value="">اختر البيان</option>
              {descriptionList?.map((description, i) => {
                return (
                  <option key={i} value={description.item}>
                    {description.item}
                  </option>
                );
              })}
            </select>
            <span
              className="input-group-text md-addon"
              id="inputGroupMaterial-sizing-default"
            >
              البيان
            </span>
          </div>
          <div className={paymentMethod === 'صك' ? '' : 'd-none'}>
            <div className="md-form input-group">
              <input
                name="cheque"
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroupMaterial-sizing-default"
                style={{ direction: 'rtl' }}
                value={form.cheque}
                onChange={formFillout}
              />
              <div className="input-group-prepend">
                <span
                  className="input-group-text md-addon"
                  id="inputGroupMaterial-sizing-default"
                >
                  رقم الصك
                </span>
              </div>
            </div>
            <div className="md-form input-group">
              <select
                name="bank"
                className="browser-default custom-select"
                style={{ direction: 'rtl' }}
                value={form.bank}
                onChange={formFillout}
              >
                <option value="">اختر المصرف</option>
                {bankList?.map((item, i) => {
                  return (
                    <option key={i} value={item.bankName}>
                      {item.bankName}
                    </option>
                  );
                })}
              </select>
              <span
                className="input-group-text md-addon"
                id="inputGroupMaterial-sizing-default"
              >
                المصرف
              </span>
            </div>
          </div>
          <div>
            <MDBBtn color="danger" onClick={clearForm}>
              <MDBIcon icon="trash-alt" />
            </MDBBtn>
            <MDBBtn color="success" onClick={submitForm}>
              <MDBIcon icon="save" />
            </MDBBtn>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
