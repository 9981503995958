import React, { useState, useEffect, useContext } from 'react';
import './BanksAndClients.css';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalState';

const ClientsManagement = () => {
  const { setAlert, clearAlert } = useContext(GlobalContext);
  const [clientList, setClientList] = useState([]);
  const [client, setClient] = useState('');
  const [tempClient, setTempClient] = useState('');
  const [clientId, setClientId] = useState('');

  const getClientList = async () => {
    const response = (await axios.get('/management/clients')).data;
    if (response && response.data) {
      setClientList(response.data);
    }
  };

  useEffect(() => {
    getClientList();
  }, []);

  const addResource = async () => {
    if (tempClient) {
      const response = (
        await axios.post('/management/clients', {
          clientname: tempClient,
        })
      ).data;
      if (response && response.data) {
        setClientList(response.data);
        setClient('');
        setTempClient('');
      }
    }
  };
  const editResource = async () => {
    if (clientId) {
      const response = await axios.put(
        `/management/clients/${clientId}`,

        { clientname: tempClient }
      );
      if (response && response.data) {
        setClientList(response.data);
        setClient('');
        setTempClient('');
      }
    }
  };

  const deleteResource = async () => {
    if (tempClient) {
      const deleteClient = window.confirm(
        `هل أنت متأكد من حذف الزبون ${tempClient}`
      );
      if (deleteClient) {
        try {
          const response = await axios.delete(
            `/management/clients/${clientId}`
          );
          if (response && response.data && response.data.success) {
            clearAlert();
            setClientList(response.data);
            setClient('');
            setTempClient('');
          }
        } catch (error) {
          setAlert('client may not be available or has records');
        }
      }
    }
  };

  const handleSelect = (e) => {
    setClient(e.target.value);
    e.target.value &&
      setClientId(
        clientList.filter((x) => x.clientname === e.target.value)[0].id
      );
    setTempClient(e.target.value);
  };

  const handleInput = (e) => {
    setTempClient(e.target.value);
  };

  return (
    <>
      <div>
        <label>خيارات الزبائن</label>
        <br />
        <button
          onClick={addResource}
          className="BCButton"
          style={{ marginLeft: '0.5rem' }}
        >
          &#10004;
        </button>
        <input
          name="resource"
          className="BCInput"
          value={tempClient}
          onChange={handleInput}
        />
        <button onClick={editResource} className="BCButton">
          &#9998;
        </button>
        <button onClick={deleteResource} className="BCButton">
          &#10006;
        </button>
        <br />
        <select className="BCSelect" value={client} onChange={handleSelect}>
          <option value="">اسم الزبون</option>
          {clientList?.map((client, i) => {
            return (
              <option key={client.id} value={client.clientname}>
                {client.clientname}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default ClientsManagement;
